import React, { FC } from 'react'
import {
    Container,
    LineContainer,
    List,
    ListTitle,
    Map,
    Title,
    Wrapper
} from './ContactsPageStyled'
import SocialLink from '../../UI/Links/SocialLink/SocialLink'

import whatsappIcon from '../../images/social/icon_whatsapp.svg'
import telegramIcon from '../../images/social/icon_telegram.svg'
import facebookIcon from '../../images/social/icon_facebook.svg'
import instagramIcon from '../../images/social/icon_instagram.svg'
import { Line } from '../../UI/Text/TextPage'

const ContactsPage: FC = () => {
    return (
        <Wrapper>
            <Title>Contact Us</Title>
            <Container>
                <List>
                    <ListTitle>In Messengers</ListTitle>
                    <SocialLink
                        url="https://wa.me/447824191144"
                        icon={whatsappIcon}
                    >
                        WhatsApp
                    </SocialLink>
                    <SocialLink
                        url="https://tgtg.su/Jelenas_Cake_Ltd"
                        icon={telegramIcon}
                    >
                        Telegram
                    </SocialLink>
                </List>
                <List>
                    <ListTitle>Phone</ListTitle>
                    <SocialLink url="tel:+44 7824 191144">
                        +44 7824 191144
                    </SocialLink>
                </List>
                <List>
                    <ListTitle>Email</ListTitle>
                    <SocialLink url="mailto:jelenascake@yahoo.co.uk">
                        jelenascake@yahoo.co.uk
                    </SocialLink>
                </List>
                <List>
                    <ListTitle>Follow Us</ListTitle>
                    <LineContainer>
                        <SocialLink
                            url="https://www.facebook.com/pg/jelenascake.co.uk"
                            icon={facebookIcon}
                        />
                        <SocialLink
                            url="https://instagram.com/jelenas_cake_ltd"
                            icon={instagramIcon}
                        />
                    </LineContainer>
                </List>
            </Container>
            <Map
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.1361417368325!2d0.010593185705566405!3d51.510718272630804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a5cfb5421dbb%3A0x42238edb99a9c277!2sJelena&#39;s%20Cake!5e0!3m2!1sru!2sth!4v1741183547715!5m2!1sru!2sth"
                width="600"
                height="450"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
            <Line>
                ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
                ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
            </Line>
        </Wrapper>
    )
}

export default ContactsPage
